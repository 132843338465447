<template>
  <info-card-row
    :title="title"
    :sub-title="$vuetify.breakpoint.smAndUp ? subTitle : ''"
    :icon="icon"
  >
    <slot />
  </info-card-row>
</template>

<script>
// wrapper around info-card-row which hides subTitle on small screens
import InfoCardRow from "@components/base/InfoCardRow";
export default {
  name: "SettingRow",
  components: { InfoCardRow },
  props: {
    icon: {
      type: String,
      required: false,
    },
    iconColor: {
      type: String,
      required: false,
      default: "primary",
    },
    title: {
      type: [String, typeof TranslateResult],
      required: false,
    },
    subTitle: {
      type: [String, typeof TranslateResult],
      required: false,
    },
  },
};
</script>

<style scoped></style>
