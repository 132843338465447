<template>
  <v-select
    :items="getCustomScreens"
    item-text="Name"
    @change="onChange"
    v-model="selected"
    return-object
    style="max-width: 175px"
    clearable
    @click:clear="onClear"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeSelector",
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapGetters(["getCustomScreens"]),
    ...mapGetters("settings", ["getHome"]),
  },
  methods: {
    async onChange(item) {
      if (!item) return;
      await this.$store.dispatch("settings/setHome", item.Id);
    },
    async onClear() {
      await this.$store.dispatch("settings/setHome", "undefined");
    },
  },
  mounted() {
    this.selected = this.getCustomScreens.find((s) => s.Id === this.getHome);
  },
};
</script>

<style scoped></style>
