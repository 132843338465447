<template>
  <base-renderless-dialog>
    <template #default="{ openDialog }">
      <v-select
        :items="languages"
        item-text="label"
        @change="(item) => openDialog(item)"
        v-model="language"
        return-object
        style="max-width: 175px"
      />
    </template>

    <template #dialog="{ data, listeners }">
      <base-confirm-dialog
        :item="data"
        v-on="listeners"
        :accept-function="setLanguage"
        :deny-function="init"
      >
        <template #title>
          {{ $t("settings.refreshQuestion") }}
        </template>
        <template #subTitle="{ item }">
          <p>
            {{ $t("settings.refreshExplanation") }}
          </p>
          <p class="font-weight-bold">
            {{ item.label }}
          </p>
        </template>
      </base-confirm-dialog>
    </template>
  </base-renderless-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LanguageSelector",
  data() {
    return {
      language: {},
      languages: [
        { code: "ro", label: "Român" },
        { code: "de", label: "Deutsch" },
        { code: "nl", label: "Nederlands" },
        { code: "fr", label: "Français" },
        { code: "tr", label: "Türk" },
        { code: "en", label: "English" },
        { code: "fi", label: "Suomi" },
        { code: "ru", label: "русский" },
        { code: "he", label: "עברית" },
        { code: "pt", label: "Português" },
        { code: "fa", label: "فارسی" },
        { code: "hr", label: "hrvatski" },
        { code: "hu", label: "magyar" },
        { code: "ja", label: "日本語" },
        { code: "cs", label: "čeština" },
        { code: "ko", label: "한국어" },
        { code: "pl", label: "Polski" },
        { code: "zh", label: "捷克语" },
        { code: "sv", label: "Svenska" },
        { code: "it", label: "Italiano" },
        { code: "es", label: "Español" },
      ],
    };
  },
  computed: {
    ...mapGetters("settings", ["getLanguage"]),
    currentLanguage() {
      return this.languages.find((l) => l.code === this.getLanguage);
    },
  },
  methods: {
    init() {
      this.language = this.currentLanguage;
    },
    setLanguage(language) {
      this.$store.dispatch("settings/setLanguage", language.code).then(() => {
        this.reload();
      });
    },
    reload() {
      window.location.reload();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped></style>
