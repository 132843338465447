<template>
  <LayoutEmpty>
    <v-row>
      <v-spacer />
      <v-col cols="12" lg="5">
        <base-card
          :title="$t('settings.settings')"
          icon="mdi-close"
          @click="navigate"
        >
          <v-divider />

          <v-list>
            <v-subheader>
              {{ $t("settings.general") }}
            </v-subheader>

            <setting-row
              :title="$t('settings.language')"
              :sub-title="$t('settings.choosePreferredLanguage')"
              icon="mdi-earth"
            >
              <language-selector />
            </setting-row>

            <setting-row
              v-if="!getIsProvidingService && getFeatureToggles.home"
              :title="$t('settings.home')"
              :sub-title="$t('settings.chooseHome')"
              icon="mdi-home"
            >
              <home-selector />
            </setting-row>
          </v-list>
          <v-divider />

          <v-list>
            <v-subheader>
              {{ $t("settings.tooltips") }}
            </v-subheader>

            <setting-row
              :title="$t('settings.tooltipChart')"
              :sub-title="$t('settings.tooltipChartSubtitle')"
              icon="mdi-chart-bell-curve-cumulative"
            >
              <base-checkbox
                v-model="getFeatureToggles.tooltipChart"
                @changed="onTooltipChartChanged"
              />
            </setting-row>

            <setting-row
              :title="$t('settings.tooltipEvents')"
              :sub-title="$t('settings.tooltipEventsSubtitle')"
              icon="mdi-text-box-multiple-outline"
            >
              <base-checkbox
                v-model="getFeatureToggles.tooltipEvents"
                @changed="onTooltipEventsChanged"
              />
            </setting-row>

            <setting-row
              :title="$t('settings.numberOfEvents')"
              :sub-title="$t('settings.numberOfEventsSubtitle')"
              icon="mdi-format-list-numbered"
            >
              <v-text-field
                @change="onTooltipNumberOfEventsChanged"
                type="number"
                color="primary"
                v-model="getFeatureToggles.tooltipNumberOfEvents"
                :disabled="!getFeatureToggles.tooltipEvents"
                style="max-width: 50px"
              />
            </setting-row>
          </v-list>

          <v-divider />

          <v-list>
            <v-subheader>
              {{ $t("settings.advanced") }}
            </v-subheader>

            <setting-row
              :title="$t('settings.clearCache')"
              :sub-title="$t('settings.clearCache')"
              icon="mdi-cached"
            >
              <base-button :loading="clearingCache" @click="clearCache">
                {{ $t("settings.clearCache") }}
              </base-button>
            </setting-row>
          </v-list>
        </base-card>
      </v-col>
      <v-spacer />
    </v-row>
  </LayoutEmpty>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageSelector from "@components/settings/LanguageSelector";
import { clear } from "@plugins/local-forage";
import HomeSelector from "@components/settings/HomeSelector";
import SettingRow from "@components/settings/SettingRow";

export default {
  name: "SettingsPage",
  page: {
    title: "Settings",
  },
  data() {
    return {
      clearingCache: false,
    };
  },
  components: {
    SettingRow,
    HomeSelector,
    LanguageSelector,
  },
  computed: {
    ...mapGetters("settings", ["getFeatureToggles"]),
    ...mapGetters("auth", ["getIsProvidingService"]),
  },
  methods: {
    navigate() {
      this.$helpers.pushRoute("/");
    },
    onTooltipChartChanged(event) {
      this.$store.dispatch("settings/setTooltipChart", event, { root: true });
    },
    onTooltipEventsChanged(event) {
      this.$store.dispatch("settings/setTooltipEvents", event, { root: true });
    },
    onTooltipNumberOfEventsChanged() {
      this.$store.dispatch(
        "settings/setTooltipNumberOfEvents",
        Number(this.getFeatureToggles.tooltipNumberOfEvents),
        { root: true }
      );
    },
    clearCache() {
      this.clearingCache = true;
      clear().then(() => (this.clearingCache = false));
    },
  },
};
</script>

<style scoped></style>
